import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Alert, Spin } from '@retail-core/rds';
import ErrorBoundary from './ErrorBoundary';

const ErrorRoute = lazy(() => import('routes/errorRoute/ErrorRoute'));
const Impressum = lazy(() => import('routes/impressum/Impressum'));
const SupplierUserManagement = lazy(() => import('routes/supplierUserManagement/SupplierUserManagement'));
const SupplierRegistration = lazy(() => import('routes/supplierRegistration/SupplierRegistration'));
const LoginRedirect = lazy(() => import('routes/loginRedirect/LoginRedirect'));
const Compaz = lazy(() => import('routes/compaz/Compaz'));
const UserProfile = lazy(() => import('routes/userProfile/UserProfile'));
const Documents = lazy(() => import('routes/documents/Documents'));
const ExtensionVerification = lazy(() => import('routes/extensionVerification/ExtensionVerification'));
const DeletedUsers = lazy(() => import('routes/supplierUserManagement/deletedUsers/DeletedUsers'));
const InternalUserRoles = lazy(() => import('routes/supplierUserManagement/internalUserRoles/'));

export const impressumPath = '/app/zalando-impressum/';

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <div id="app">
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Spin />}>
            <Switch>
              <Route exact path="/" component={LoginRedirect} />
              <Route path={impressumPath} component={Impressum} />
              <Route path="/app/user-management/:status?" component={SupplierUserManagement} />
              <Route
                path="/app/deleted-users/:email?"
                render={() => (
                  <Alert.ErrorBoundary>
                    <DeletedUsers />
                  </Alert.ErrorBoundary>
                )}
              />
              <Route
                path="/app/users-role/:email?"
                render={() => (
                  <Alert.ErrorBoundary>
                    <InternalUserRoles />
                  </Alert.ErrorBoundary>
                )}
              />
              <Route
                path="/app/onboarding-supplier-registration/:secret"
                render={() => (
                  <ErrorRoute
                    title={'Unfortunately, your invitation has expired.'}
                    errorType={'NOT_FOUND'}
                  />
                )}
              />
              <Route
                path="/app/supplier-registration/:secret"
                render={props => <SupplierRegistration impressumPath={impressumPath} {...props} />}
              />
              <Route path="/app/compaz" component={Compaz} />
              <Route path="/app/user-profile" component={UserProfile} />
              <Route path="/app/documents/:fileName?" component={Documents} />
              <Route path="/app/extension-verification/:secret" component={ExtensionVerification} />
              <Route component={ErrorRoute} />
            </Switch>
          </Suspense>
        </QueryClientProvider>
      </div>
    </ErrorBoundary>
  );
};

export default App;
