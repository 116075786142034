import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'core-js/modules/es.set';
import 'core-js/modules/es.array.find-index';
import App from './components/app';
import { initSentry } from 'utils/sentry';
import { onDOMContentLoaded, onLoad } from 'utils/performance-metrics';

window.addEventListener('load', onLoad);

document.addEventListener('DOMContentLoaded', (event) => {
  onDOMContentLoaded(event)
  window.RetailPortal.eventEmitter.on('application-ready', () => {
    initSentry();
    ReactDOM.render(
      <React.StrictMode>
        <Router>
          <App />
        </Router>
      </React.StrictMode>,
      document.getElementById('app-container')
    );
  });
});
